import { WithRouterProps } from 'next/dist/client/with-router'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'

import { Emoji, Modal, Paragraph, Title } from './style'

import { InferProps, func, string } from 'prop-types'

export default function CartSizeMismatch(props: InferProps<typeof CartSizeMismatch.propTypes> & WithRouterProps) {
  const handleCloseRequest = () => {
    const { alertKey, resetCartSizeMismatch, router, setAlertTypeVisibility } = props
    resetCartSizeMismatch()
    setAlertTypeVisibility(alertKey, false)
    // refresh page to make sure that unavailable items are cleared from menu
    router.reload()
  }

  return (
    <EazeDrawer open onRequestClose={handleCloseRequest}>
      <Modal>
        <Emoji>😓</Emoji>
        <Title>Unavailable items removed from cart</Title>
        <Paragraph>
          Sorry! We had to remove one or more items from your cart because they are no longer available.
        </Paragraph>
        <Button onClick={handleCloseRequest}>Keep Shopping</Button>
      </Modal>
    </EazeDrawer>
  )
}
CartSizeMismatch.propTypes = {
  alertKey: string,
  resetCartSizeMismatch: func,
  setAlertTypeVisibility: func
}
