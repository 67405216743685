import styled from '@emotion/styled'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'

import { CloseModalIcon, Comment, Emoji, Modal, Paragraph, Title } from './style'

import { arrayOf, func, string } from 'prop-types'

const VerificationDeclined = ({ alertKey, setAlertTypeVisibility, declinedReasons, declinedComment }) => {
  const onClick = () => {
    setAlertTypeVisibility(alertKey, false)
  }

  const reasons = declinedReasons?.map((reason, i) => {
    return <Reasons key={i}>{reason}</Reasons>
  })

  const comments =
    declinedComment && declinedComment.length > 0 ? (
      <Comment>
        <Title>Comments:</Title>
        <Paragraph>{declinedComment}</Paragraph>
      </Comment>
    ) : null

  return (
    <EazeDrawer open onRequestClose={onClick}>
      <CloseModalIcon onClick={onClick} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>😢</Emoji>
        <Title>We had some issues verifying your account</Title>
        <Paragraph>{reasons}</Paragraph>
        {comments}
        <Button onClick={onClick}>Fix it</Button>
      </Modal>
    </EazeDrawer>
  )
}

export default VerificationDeclined

VerificationDeclined.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  declinedReasons: arrayOf(string),
  declinedComment: string
}

const Reasons = styled.div`
  margin-bottom: 1rem;
`
