import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'

import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from './style'

export default function RemovedPromo({ toggleRemovedPromo = () => null }: { toggleRemovedPromo: () => void }) {
  return (
    <EazeDrawer open onRequestClose={toggleRemovedPromo}>
      <CloseModalIcon onClick={toggleRemovedPromo} src="/static/icons/close.svg" alt="Close Popup" />
      <Modal>
        <Emoji>😢</Emoji>
        <Title>{`Your promo won't work anymore`}</Title>
        <Paragraph>
          {`Looks like your cart doesn't meet the promo criteria. We had to remove the promo from your cart.`}
        </Paragraph>
        <Button onClick={toggleRemovedPromo}>OK</Button>
      </Modal>
    </EazeDrawer>
  )
}
