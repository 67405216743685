/**
 * Non existent account modal component
 * Informs user the email or phone number they tried is not tied to an account
 * and gives the option to sign up or try a different identifier
 */
import { PureComponent } from 'react'

import { WithRouterProps } from 'next/dist/client/with-router'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import ROUTES from '@helpers/routes'

import { LinkButton, LinkContainer, Modal, Paragraph, Title } from './style'

import { InferProps, func, string } from 'prop-types'

export default class NonExistentAccount extends PureComponent<
  InferProps<typeof NonExistentAccount.propTypes> & WithRouterProps
> {
  static propTypes = {
    alertKey: string,
    setAlertTypeVisibility: func
  }

  handleRequestUseDifferentIdentifier = () => {
    const { alertKey, setAlertTypeVisibility } = this.props
    setAlertTypeVisibility(alertKey, false)
  }

  handleRequestNewAccount = () => {
    const { alertKey, setAlertTypeVisibility, router } = this.props
    setAlertTypeVisibility(alertKey, false)
    router.push(ROUTES.SIGNUP)
  }

  render() {
    return (
      <EazeDrawer open allowClose={false} onRequestClose={this.handleRequestUseDifferentIdentifier}>
        <Modal>
          <Title>Oops!</Title>
          <Paragraph>{`That email address doesn't match our records. Try again or sign up for a new account.`}</Paragraph>
          <Button onClick={this.handleRequestNewAccount}>Create a new account</Button>
          <LinkContainer>
            <LinkButton onClick={this.handleRequestUseDifferentIdentifier}>Try a different email</LinkButton>
          </LinkContainer>
        </Modal>
      </EazeDrawer>
    )
  }
}
