import { PureComponent } from 'react'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { TEST_IDS } from '@components/modal/test/constants'
import { track } from '@helpers/analytics'

import { CloseModalIcon, Emoji, Modal, Paragraph, Title } from './style'

import { InferProps, func, shape, string } from 'prop-types'

export default class CashOnly extends PureComponent<InferProps<typeof CashOnly.propTypes>> {
  static propTypes = {
    activeDepot: shape({
      id: string
    }),
    alertKey: string,
    resetCurrentAction: func,
    setAlertTypeVisibility: func
  }

  componentDidMount() {
    const { activeDepot } = this.props
    track('CashOnly.Modal', { depotId: activeDepot?.id })
  }

  handleClick = () => {
    const { alertKey, resetCurrentAction, setAlertTypeVisibility } = this.props
    resetCurrentAction()
    setAlertTypeVisibility(alertKey, false)
  }

  render() {
    return (
      <EazeDrawer open onRequestClose={this.handleClick}>
        <CloseModalIcon onClick={this.handleClick} src="/static/icons/close.svg" />
        <Modal data-e2eid={TEST_IDS.CASH_ONLY_COMPONENT}>
          <Emoji>💵</Emoji>
          <Title>Cash Only</Title>
          <Paragraph>We are temporarily experiencing payment issues. Apologies for the inconvenience.</Paragraph>
          <Button onClick={this.handleClick} data-e2eid={TEST_IDS.CASH_ONLY_DISMISS_BUTTON}>
            OK
          </Button>
        </Modal>
      </EazeDrawer>
    )
  }
}
