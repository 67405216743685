import { EMAIL } from '@helpers/constants'
import { alertTypes } from 'redux/alert/config/types'

import Modal2FA from './2FA-modal'
import AgeGate from './age-gate'
import Announcement from './announcement'
import AppOutdated from './app-outdated'
import CartExpired from './cart-expired'
import CartSizeMismatch from './cart-size-mismatch'
import CashOnlyModal from './cash-only'
import ChangedPassword from './changed-password'
import CollectiveAgreement from './collective-agreement'
import DepotMismatch from './depot-mismatch'
import DuplicateAccountDetected from './duplicate-account-detected'
import ExistingAccountIdentifier from './existing-account-identifier'
import LocationOutOfService from './location-out-of-service'
import NonExistentAccountIdentifier from './non-existent-account-identifier'
import PhoneVerificationIncomplete from './phone-verification-incomplete'
import RemovedPromo from './removed-promo'
import ShowIDs from './show-ids'
import StoreClosed from './store-closed'
import UnderAgeMinimum from './under-age-minimum'
import UnderMinimum from './under-minimum'
import UploadError from './upload-error'
import VerificationDeclined from './verification-declined'

const Modal = ({
  abortCheckoutNewDepot,
  acceptCollectiveAgreement,
  activeDepot,
  alertKey,
  cartMinimum,
  clearPotentialAddress,
  closedMessage,
  currentAction,
  declinedComment,
  declinedReasons,
  logout,
  newestAnnouncement,
  promoCode,
  recUrl,
  resetCartSizeMismatch,
  resetCurrentAction,
  resetDepotMismatch,
  router,
  setAlertTypeVisibility,
  setChangePassword,
  setCookie,
  setDismissedAnnouncements,
  setExpiredCart,
  toggleAddress,
  toggleRemovedPromo,
  verificationProcessErrors
}) => {
  let modal

  switch (alertKey) {
    case alertTypes.ANNOUNCEMENT:
      modal = (
        <Announcement newestAnnouncement={newestAnnouncement} setDismissedAnnouncements={setDismissedAnnouncements} />
      )
      break

    case alertTypes.AGE_GATE:
      modal = <AgeGate setCookie={setCookie} />
      break

    case alertTypes.CART_EXPIRED:
      modal = (
        <CartExpired
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          setExpiredCart={setExpiredCart}
        />
      )
      break

    case alertTypes.CART_SIZE_MISMATCH:
      modal = (
        <CartSizeMismatch
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          resetCartSizeMismatch={resetCartSizeMismatch}
          router={router}
        />
      )
      break

    case alertTypes.CHANGED_PASSWORD:
      modal = <ChangedPassword setChangePassword={setChangePassword} />
      break

    case alertTypes.COLLECTIVE_AGREEMENT_NOT_SIGNED:
      modal = (
        <CollectiveAgreement
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          acceptCollectiveAgreement={acceptCollectiveAgreement}
        />
      )
      break

    case alertTypes.CASH_ONLY:
      modal = (
        <CashOnlyModal
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          resetCurrentAction={resetCurrentAction}
          activeDepot={activeDepot}
        />
      )
      break

    case alertTypes.DEPOT_MISMATCH:
      modal = (
        <DepotMismatch
          abortCheckoutNewDepot={abortCheckoutNewDepot}
          alertKey={alertKey}
          promoCode={promoCode}
          resetDepotMismatch={resetDepotMismatch}
          setAlertTypeVisibility={setAlertTypeVisibility}
        />
      )
      break

    case alertTypes.EXISTING_EMAIL_ADDRESS:
      modal = (
        <ExistingAccountIdentifier
          router={router}
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          identifierType={EMAIL}
          logout={logout}
        />
      )
      break

    case alertTypes.EXISTING_PHONE_NUMBER:
      modal = (
        <ExistingAccountIdentifier
          router={router}
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          logout={logout}
        />
      )
      break

    case alertTypes.IMAGE_UPLOAD_ERROR:
      modal = (
        <UploadError
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          verificationProcessErrors={verificationProcessErrors.upload}
        />
      )
      break

    case alertTypes.NEED_TO_SHOW_ID:
      modal = <ShowIDs alertKey={alertKey} recUrl={recUrl} setAlertTypeVisibility={setAlertTypeVisibility} />
      break

    case alertTypes.NEED_2FA_REAUTH:
      modal = <Modal2FA />
      break

    case alertTypes.HAS_LINKED_ACCOUNTS:
      modal = (
        <DuplicateAccountDetected
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          resetCurrentAction={resetCurrentAction}
        />
      )
      break

    case alertTypes.NON_EXISTENT_EMAIL:
      modal = (
        <NonExistentAccountIdentifier
          alertKey={alertKey}
          router={router}
          setAlertTypeVisibility={setAlertTypeVisibility}
        />
      )
      break

    case alertTypes.LOCATION_OUT_OF_SERVICE:
      modal = (
        <LocationOutOfService
          alertKey={alertKey}
          clearPotentialAddress={clearPotentialAddress}
          resetCurrentAction={resetCurrentAction}
          setAlertTypeVisibility={setAlertTypeVisibility}
          toggleAddress={toggleAddress}
        />
      )
      break

    case alertTypes.PHONE_VERIFICATION_INCOMPLETE:
      modal = <PhoneVerificationIncomplete router={router} logout={logout} />
      break

    case alertTypes.REMOVED_PROMO:
      modal = <RemovedPromo toggleRemovedPromo={toggleRemovedPromo} />
      break

    case alertTypes.STORE_CLOSED:
      modal = (
        <StoreClosed
          closedMessage={closedMessage}
          resetCurrentAction={resetCurrentAction}
          currentAction={currentAction}
          router={router}
        />
      )
      break

    case alertTypes.UNDER_AGE_MINIMUM:
      modal = <UnderAgeMinimum router={router} />
      break

    case alertTypes.UNDER_MINIMUM:
      modal = (
        <UnderMinimum
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          cartMinimum={cartMinimum}
          router={router}
        />
      )
      break

    case alertTypes.VERIFICATION_DECLINED:
      modal = (
        <VerificationDeclined
          alertKey={alertKey}
          setAlertTypeVisibility={setAlertTypeVisibility}
          declinedReasons={declinedReasons}
          declinedComment={declinedComment}
        />
      )
      break

    case alertTypes.VERSION_MISMATCH:
      modal = <AppOutdated router={router} />
      break

    default:
      modal = null
  }

  return modal
}

export default Modal
