import Link from 'next/link'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'

import { CloseModalIcon, Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from './style'

import { func, string } from 'prop-types'

const ShowIDs = ({ alertKey, setAlertTypeVisibility, recUrl }) => {
  const requestClose = () => {
    setAlertTypeVisibility(alertKey, false)
  }

  return (
    <EazeDrawer open onRequestClose={requestClose}>
      <CloseModalIcon onClick={requestClose} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>🏛</Emoji>
        <Title>Have Your IDs Ready</Title>
        <Paragraph>
          Your driver must confirm your state ID and medical recommendation in order to complete your delivery.
        </Paragraph>
        <Button onClick={requestClose}>Okay, got it!</Button>

        {recUrl && (
          <LinkContainer>
            <Link href={recUrl} style={{ width: '100%' }} passHref>
              <LinkButton rel="noopener noreferrer" target="_blank">
                View my medical card
              </LinkButton>
            </Link>
          </LinkContainer>
        )}
      </Modal>
    </EazeDrawer>
  )
}

export default ShowIDs

ShowIDs.propTypes = {
  alertKey: string,
  recUrl: string,
  setAlertTypeVisibility: func
}
