import { WithRouterProps } from 'next/dist/client/with-router'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import ROUTES from '@helpers/routes'

import { Emoji, Modal, Paragraph, Title } from './style'

const UnderAgeMinimum = ({ router }: WithRouterProps) => {
  const requestClose = () => {
    router.push(ROUTES.MENU)
  }

  return (
    <EazeDrawer open allowClose={false}>
      <Modal>
        <Emoji>👶</Emoji>
        <Title>{`We're sorry!`}</Title>
        <Paragraph>You must be 21 or older to receive a delivery at your current location.</Paragraph>

        <Button onClick={requestClose}>Return to menu</Button>
      </Modal>
    </EazeDrawer>
  )
}

export default UnderAgeMinimum
