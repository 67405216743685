import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import ROUTES from '@helpers/routes'

import { CloseModalIcon, Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from './style'

import { func, object, string } from 'prop-types'

const UnderMinimum = ({ alertKey, setAlertTypeVisibility, cartMinimum, router }) => {
  const onRequestClose = () => {
    setAlertTypeVisibility(alertKey, false)
  }
  const onClick = () => {
    router.push(ROUTES.MENU)
  }

  return (
    <EazeDrawer open onRequestClose={onRequestClose}>
      <CloseModalIcon onClick={onRequestClose} src="/static/icons/close.svg" />
      <Modal>
        <Emoji>🙌</Emoji>
        <Title>Almost there!</Title>
        <Paragraph>
          Eaze has a ${cartMinimum} minimum to place an order. Add items to your cart to meet the minimum.
        </Paragraph>
        <Button onClick={onClick}>Browse full Eaze Menu</Button>
        <LinkContainer>
          <LinkButton onClick={onRequestClose}>View Cart</LinkButton>
        </LinkContainer>
      </Modal>
    </EazeDrawer>
  )
}

export default UnderMinimum

UnderMinimum.propTypes = {
  alertKey: string,
  setAlertTypeVisibility: func,
  cartMinimum: string,
  router: object
}
