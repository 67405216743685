import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react'
import { WithRouterProps } from 'next/dist/client/with-router'
import { useSelector } from 'react-redux'

import { getUserId } from '@/redux/auth/selectors'
import { getPhoneNumberBasic } from '@/redux/profile/selectors'
import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { apiV2 } from '@helpers/api'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '@helpers/constants'
import errorHandler from '@helpers/error-handler'
import ROUTES from '@helpers/routes'

import { Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from './style'

import { InferProps, func } from 'prop-types'

PhoneVerificationIncomplete.propTypes = {
  logout: func
}
export default function PhoneVerificationIncomplete(
  props: InferProps<typeof PhoneVerificationIncomplete.propTypes> & WithRouterProps
) {
  const userPhoneNumber = useSelector(getPhoneNumberBasic)
  const userId = useSelector(getUserId)

  async function handleNavigation() {
    if (userPhoneNumber) {
      const { err } = await apiV2.verifyPhoneNumber({ verificationType: 'sms' })
      if (err) {
        errorHandler(new Error(`Encountered error sending OTP from verify phone modal: ${err.message}`), {
          user: { id: userId }
        })
      }
    }
    props.router.push(ROUTES.VERIFY)
  }

  return (
    <EazeDrawer open allowClose={false}>
      <SplitClient splitKey={userId}>
        <SplitTreatments names={[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA]}>
          {({ isReady, treatments }) => {
            const splitIsOn =
              treatments[SPLIT_EXPERIMENTS.PROFILE_PAGE_2FA].treatment === SPLIT_TREATMENTS.PROFILE_PAGE_2FA.ON
            if (isReady && splitIsOn && userPhoneNumber) {
              return (
                <Modal>
                  <Emoji>📱</Emoji>
                  <Title>Verify your new phone</Title>
                  <Paragraph>{`We'll need to verify your new phone number ending in ${userPhoneNumber.slice(
                    -4
                  )} before saving it to your account`}</Paragraph>
                  <Button onClick={handleNavigation}>Get verified</Button>
                </Modal>
              )
            } else {
              return (
                <Modal>
                  <Emoji>📱</Emoji>
                  <Title>Verify your phone</Title>
                  <Paragraph>To complete Eaze sign up, you need to verify your phone number.</Paragraph>
                  <Button onClick={handleNavigation}>Get verified</Button>
                  <LinkContainer>
                    <LinkButton href="/login" onClick={props.logout}>
                      Sign in as another user
                    </LinkButton>
                  </LinkContainer>
                </Modal>
              )
            }
          }}
        </SplitTreatments>
      </SplitClient>
    </EazeDrawer>
  )
}
