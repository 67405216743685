import { PureComponent } from 'react'

import styled from '@emotion/styled'
import Image from 'next/image'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { TEST_IDS } from '@components/modal/announcement/test/constants'
import { isMobile } from '@helpers/constants'

import { Message, Modal, Paragraph, Title } from './style'

import { InferProps, func, shape, number, string } from 'prop-types'

/*
 * Not sure if this is even used in production
 * When used in staging, the attached img is not reachable so using state to hide container and only show if it loads
 */
export default class Announcement extends PureComponent<InferProps<typeof Announcement.propTypes>> {
  state = {
    imgLoads: false
  }

  static propTypes = {
    newestAnnouncement: shape({
      id: number,
      photoUrl: string,
      name: string,
      description: string
    }),
    setDismissedAnnouncements: func
  }

  handleCloseRequest = () => {
    const { newestAnnouncement, setDismissedAnnouncements } = this.props
    setDismissedAnnouncements(newestAnnouncement.id)
  }

  render() {
    const { newestAnnouncement } = this.props

    return (
      <EazeDrawer open onRequestClose={this.handleCloseRequest}>
        <Modal data-e2eid={TEST_IDS.COMPONENT}>
          {this.state.imgLoads && (
            <ImageContainer>
              <Image
                src={isMobile ? `${newestAnnouncement.photoUrl}?preset=thumb` : newestAnnouncement.photoUrl}
                layout="fill"
                onError={() => this.setState({ imgLoads: false })}
                onLoad={() => this.setState({ imgLoads: true })}
              />
            </ImageContainer>
          )}
          <Message>
            <Title>{newestAnnouncement.name}</Title>
            <Paragraph>{newestAnnouncement.description}</Paragraph>
          </Message>
          <Button variant="variant-9" onClick={this.handleCloseRequest} data-e2eid={TEST_IDS.DISMISS_BUTTON}>
            Continue Browsing
          </Button>
        </Modal>
      </EazeDrawer>
    )
  }
}

const ImageContainer = styled.div`
  height: 23rem;
  overflow: hidden;
`
