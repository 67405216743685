/**
 * Existing account modal component
 * Informs user an account already exists for the identifier submitted
 */

import { PureComponent } from 'react'

import { WithRouterProps } from 'next/dist/client/with-router'

import { Button } from '@/stories/buttons/button'
import EazeDrawer from '@/stories/drawer'
import { TEST_IDS } from '@components/modal/test/constants'
import { track } from '@helpers/analytics'
import { EMAIL } from '@helpers/constants'
import ROUTES from '@helpers/routes'

import { Emoji, LinkButton, LinkContainer, Modal, Paragraph, Title } from './style'

import { InferProps, func, string } from 'prop-types'

export default class ExistingAccountIdentifier extends PureComponent<
  InferProps<typeof ExistingAccountIdentifier.propTypes & WithRouterProps>
> {
  static propTypes = {
    alertKey: string,
    identifierType: string,
    logout: func,
    setAlertTypeVisibility: func
  }

  handleRedirectToLogin = () => {
    const { alertKey, identifierType, logout, router, setAlertTypeVisibility } = this.props
    if (identifierType === EMAIL) {
      track('Signup.DuplicateEmail.Login')
    } else {
      track('Signup.DuplicatePhone.Login')
    }
    setAlertTypeVisibility(alertKey, false)
    // User in verification flow will be logged into the account they're creating and should be logged out
    if (logout) {
      logout(ROUTES.LOGIN)
    } else {
      router.push(ROUTES.LOGIN)
    }
  }

  handleRedirectToResetPassword = () => {
    const { alertKey, identifierType, router, setAlertTypeVisibility } = this.props
    if (identifierType === EMAIL) {
      track('Signup.DuplicateEmail.ResetPassword')
    } else {
      track('Signup.DuplicatePhone.ResetPassword')
    }
    setAlertTypeVisibility(alertKey, false)
    router.push(ROUTES.RESET_PASSWORD)
  }

  componentDidMount() {
    if (this.props.identifierType === EMAIL) {
      track('Signup.DuplicateEmail.View')
    } else {
      track('Signup.DuplicatePhone.View')
    }
  }

  handleCloseRequest = () => {
    const { alertKey, setAlertTypeVisibility } = this.props
    setAlertTypeVisibility(alertKey, false)
  }

  render() {
    const { identifierType } = this.props
    const identifierTypeDisplay = identifierType === EMAIL ? 'email address' : 'phone number'

    return (
      <EazeDrawer open onRequestClose={this.handleCloseRequest}>
        <Modal data-e2eid={TEST_IDS.DUPLICATE_ACCOUNT_MODAL}>
          <Emoji>👋</Emoji>
          <Title>Good to see you again!</Title>
          <Paragraph>
            Your {identifierTypeDisplay} has already been used to create an account. Would you like to log in or reset
            your password?
          </Paragraph>

          <Button onClick={this.handleRedirectToLogin}>Log in</Button>
          <LinkContainer>
            <LinkButton onClick={this.handleRedirectToResetPassword}>Reset my password</LinkButton>
          </LinkContainer>
        </Modal>
      </EazeDrawer>
    )
  }
}
